import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Collapse, initTE } from 'tw-elements';
import { TranslocoModule } from '@ngneat/transloco';
// import { FAQItem } from 'src/app/models/faq.model';

interface FAQItem {
  q: string;
  a: string;
  h: string;
}

@Component({
  selector: 'app-faq-accordion',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './faq-accordion.component.html',
  styleUrls: ['./faq-accordion.component.scss'],
})
export class FaqAccordionComponent {
  @Input() faqSrc: string = '';

  faqItems: FAQItem[] = [
    {
      q: 'faq.question-one',
      a: 'faq.answer-one',
      h: 'One'
    },
    {
      q: 'faq.question-two',
      a: 'faq.answer-two',
      h: 'Two'
    },
    {
      q: 'faq.question-three',
      a: 'faq.answer-three',
      h: 'Three'
    },
    {
      q: 'faq.question-four',
      a: 'faq.answer-four',
      h: 'Four'
    },
    {
      q: 'faq.question-five',
      a: 'faq.answer-five',
      h: 'Five'
    },
    {
      q: 'faq.question-six',
      a: 'faq.answer-six',
      h: 'Six'
    },
    {
      q: 'faq.question-seven',
      a: 'faq.answer-seven',
      h: 'Seven'
    },
    {
      q: 'faq.question-eight',
      a: 'faq.answer-eight',
      h: 'Eight'
    },
    {
      q: 'faq.question-nine',
      a: 'faq.answer-nine',
      h: 'Nine'
    },
    {
      q: 'faq.question-ten',
      a: 'faq.answer-ten',
      h: 'Ten'
    },
    {
      q: 'faq.question-eleven',
      a: 'faq.answer-eleven',
      h: 'Eleven'
    },
    {
      q: 'faq.question-twelve',
      a: 'faq.answer-twelve',
      h: 'Twelve'
    },
    {
      q: 'faq.question-thirteen',
      a: 'faq.answer-thirteen',
      h: 'Thirteen'
    },
    {
      q: 'faq.question-fourteen',
      a: 'faq.answer-fourteen',
      h: 'Fourteen'
    },
  ];

  constructor() {}

  faqSelected: number = -1;

  collapseFaq(index: number) {
    if (index !== this.faqSelected) {
      this.faqSelected = index;
    } else {
      this.faqSelected = -1;
    }
  }

  ngOnInit(): void {
    initTE({ Collapse });
  }
}

